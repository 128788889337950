import css from './Footer.module.css';
import React from 'react';
import ContactUsService from "../../services/ContactUsService";
import Swal from "sweetalert2";
import {useTranslation} from "react-i18next";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEnvelope, faLocationArrow} from "@fortawesome/free-solid-svg-icons";

export default function Footer() {
    const contactUsService = new ContactUsService();
    const {t} = useTranslation();

    return (
        <footer>
            <div className="container">
                <div className="row">

                    <div className="col-12 col-md-6">
                        <div className="row">
                            <div className="col">
                                <h4 className={css.title}>{t('contacts')}</h4>
                                <div>
                                    <ul className={"list-unstyled"}>
                                        <li className="mb-2">
                                            <FontAwesomeIcon icon={faLocationArrow} className="mr-2"/>
                                            <a href="https://telegram.me/karim_20_sk"
                                               className={`m-2 ${css.footer_link}`}
                                               target="_blank" rel="noreferrer">karim_20_sk</a>
                                        </li>
                                        <li className="mb-2">
                                            <FontAwesomeIcon icon={faEnvelope}/><span
                                            className="m-2">shertaev.tech@gmail.com</span>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col">
                                <h4 className={css.title}>{t('about_application')}</h4>
                                <ul className={"list-unstyled"}>
                                    <li className="mb-2"><a className={css.footer_link} href={"/privacy_policy.html"}
                                           target="_blank" rel="noreferrer">{t('privacy_policy')}</a></li>
                                    <li className="mb-2"><a className={css.footer_link} href={"/terms_and_conditions.html"}
                                           target="_blank" rel="noreferrer">{t('terms_and_conditions')}</a></li>
                                </ul>
                            </div>
                        </div>

                    </div>

                    <div className="col-12 col-md-6">
                        <h4 className={css.title}>{t('contact us')}</h4>

                        <form action="#" method="POST" onSubmit={event => {
                            event.preventDefault();
                            contactUsService.saveContact(
                                event.currentTarget.phone.value,
                                event.currentTarget.email.value,
                                event.currentTarget.message.value
                            ).then(value => {
                                console.log(value.status === 401);
                                if (value.ok) {
                                    Swal.fire({
                                        title: t('MESSAGE_SENT')
                                    });
                                } else if (value.status === 401) {
                                    Swal.fire({
                                        title: t('error.NOT_AUTH')
                                    });
                                } else {
                                    Swal.fire({
                                        title: t('error.MESSAGE_NOT_SENT')
                                    });
                                }
                            });
                        }}>
                            <div className="row mt-3">
                                <div className="col">
                                    <input type="text" name="phone" className="form-control"
                                           placeholder={t('form.phone')}/>
                                </div>
                                <div className="col">
                                    <input type="text" name="email" className="form-control"
                                           placeholder={t('form.email')}/>
                                </div>
                            </div>

                            <textarea name="message" className="form-control mt-3" rows="10" required>

                                </textarea>

                            <button type="submit" className="btn btn-primary mt-2 w-100">{t('form.send')}</button>
                        </form>
                    </div>
                </div>
            </div>
        </footer>
    );

}
